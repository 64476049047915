<template>
  <v-container v-if="obDiscountCollection.length" fluid>
    <v-subheader v-text="$t('global.discounts')" />
    <v-list color="transparent">
      <template v-for="(obDiscount, idx) in items">
        <invoice-item-discount
          :key="`invoice-discount-${idx}`"
          :disabled="arDisabled[idx] || signed"
          :item="obDiscount"
          :position="idx + 1"
          :price-with-tax="priceWithTax"
          @remove="onRemoveItem"
          @apply-discount="onApplyDiscountItem"
        />
      </template>
      <!--      <slide-y-up-transition group></slide-y-up-transition>-->
    </v-list>

    <!--
        <slide-y-up-transition group tag="div">
          <template v-for="(obDiscount, idx) in obDiscountCollection.models">
            <v-row
              :key="`invoice-discount-${idx}-${obDiscount.priority}`"
              align="center"
            >
              <v-col cols="12">
                <invoice-item-discount
                  :disabled="arDisabled[idx] || signed"
                  :item="obDiscount"
                  @remove="onRemoveItem(obDiscount)"
                  @discount:update="onApplyDiscounts"
                />
              </v-col>
            </v-row>
          </template>
        </slide-y-up-transition>
    -->
  </v-container>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import type { TaxTypeDataWithAmount } from "@/modules/invoices/mixins/InvoiceMixin";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import type { DiscountData } from "@planetadeleste/vue-mc-gw";
import { Discount, DiscountCollection } from "@planetadeleste/vue-mc-gw";
import { EventBus } from "@/services/event-bus";
import { SlideYUpTransition } from "vue2-transitions";
import InvoiceItemDiscount from "@/modules/invoices/components/discounts/InvoiceItemDiscount.vue";
import {
  debounce,
  delay,
  forEach,
  get,
  isUndefined,
  map,
  uniqueId,
} from "lodash";
import type { DebounceFunction } from "@/plugins/helpers";
import { positive, round } from "@/plugins/helpers";
import useMath from "@/composables/math";
import { applyDiscounts } from "@/modules/invoices/tools/utils";
import type { DiscountDataExt } from "@/modules/invoices/components/discounts/InvoiceInlineItemDiscount.vue";
import InvoiceInlineItemDiscount from "@/modules/invoices/components/discounts/InvoiceInlineItemDiscount.vue";

const { math, sumBy } = useMath();

@Component({
  components: {
    InvoiceInlineItemDiscount,
    SlideYUpTransition,
    InvoiceItemDiscount,
  },
})
export default class InvoiceDiscounts extends Mixins(InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;

  /**
   * Tax percent to calculate price value
   */
  @Prop([Number, String]) readonly taxPercent!: number;

  obDiscountCollection: DiscountCollection = new DiscountCollection();
  setDiscountAmountDebounced!: DebounceFunction;
  _arTaxes: TaxTypeDataWithAmount[] = [];

  get companySettings() {
    return this.company.get("settings");
  }

  get iMaxDiscounts(): number {
    return this.companySettings
      ? get(this.companySettings, "max_global_discounts", 0)
      : 0;
  }

  get arDisabled(): boolean[] {
    return map(this.obDiscountCollection.models, (obModel, index, arModels) => {
      if (index === 0) {
        return false;
      }

      return get(arModels, `${index - 1}.value`, 0) <= 0;
    });
  }

  get items(): Discount[] {
    return this.obDiscountCollection.models;
    // return map(
    //   this.obDiscountCollection.getModelList() as Partial<DiscountDataExt>[],
    //   (obItem) => {
    //     return new Discount(obItem);
    //   }
    // );
  }

  get fTotalWithoutDiscounts() {
    return round(math.add(this.fSubtotalWithoutDiscounts, this.fTaxesResult));
  }

  onWatchDiscounts(arDiscounts: DiscountData[]) {
    this.cleanItems();
    if (arDiscounts?.length) {
      forEach(arDiscounts, this.addToCollection);
    }
  }

  created() {
    this.setDiscountAmountDebounced = debounce(this.setDiscountAmount, 500, {
      maxWait: 1500,
    });
    EventBus.on("invoice.change.price", this.setDiscountAmountDebounced);
    EventBus.on("invoice.apply.discounts", this.setDiscountAmountDebounced);
    EventBus.on("invoice.add.discounts", this.onWatchDiscounts);
  }

  mounted() {
    // Clean collection
    this.obDiscountCollection.clear();

    // Iterate to discounts and fill collection
    if (this.iMaxDiscounts) {
      if (this.discounts.length) {
        forEach(this.discounts, this.addToCollection);
      } else {
        this.addToCollection();
      }

      delay(this.setDiscountAmountDebounced, 1500);
    }
  }

  /**
   * Create discount from obData param or using value = 0
   * @param {DiscountDataExt|undefined} obData
   */
  addToCollection(obData?: Partial<DiscountDataExt>) {
    if (this.obDiscountCollection.length >= this.iMaxDiscounts) {
      return;
    }

    if (this.obDiscountCollection.length) {
      const obLastDiscount = this.obDiscountCollection.last();
      if (obLastDiscount && !obLastDiscount.value) {
        return;
      }
    }

    if (isUndefined(obData)) {
      if (this.signed || !this.fTotalRounded) {
        return;
      }

      obData = {
        priority: this.obDiscountCollection.length + 1,
        value: 0,
        limit: this.priceWithTax
          ? this.getTotalWithoutDiscounts()
          : this.fSubtotalWithoutDiscounts,
      };
    }

    const obDiscount = new Discount(obData);
    obDiscount.on("change", this.setDiscountAmountDebounced);

    this.obDiscountCollection.add(obDiscount);
    this.mapDiscounts();
  }

  beforeDestroy() {
    EventBus.off("invoice.change.price");
    EventBus.off("invoice.apply.discounts");
    EventBus.off("invoice.add.discounts");
  }

  /**
   * Setting the value of the discount to 0 and then calling the setDiscountAmount method.
   * @param {Discount} obItem
   */
  onRemoveItem(obItem: Discount) {
    obItem.set("value", 0);
    // this.setDiscountAmount();
    this.cleanItems();
    this.addToCollection();
    this.onApplyDiscounts();
  }

  onApplyDiscountItem() {
    delay(this.onApplyDiscounts, 500);
  }

  /**
   * Remove all discounts with value = 0
   */
  cleanItems() {
    if (this.obDiscountCollection.length > 1) {
      this.obDiscountCollection.remove({ value: 0 });
    }
  }

  onApplyDiscounts() {
    EventBus.emit("invoice.apply.discounts");
  }

  getTaxAmount(): number {
    const arTaxes: TaxTypeDataWithAmount[] = [];
    forEach(this.positions, (obPosition) => {
      this.onSetTaxAmount(obPosition, arTaxes, false);
    });

    return sumBy<TaxTypeDataWithAmount>(arTaxes, "amount");
  }

  getTotalWithoutDiscounts(): number {
    return round(math.add(this.fSubtotalWithoutDiscounts, this.getTaxAmount()));
  }

  mapDiscounts(): boolean {
    let fPrice = this.fSubtotalWithoutDiscounts;
    let bDisabled = false;
    let hasChanges = false;

    // @ts-ignore
    this.obDiscountCollection.each((obDiscount: Discount, index: number) => {
      const fValue = round(obDiscount.calculate(fPrice), 6);
      let fDisplayValue = positive(fValue);

      if (this.inView && this.totals && this.totals.discounts) {
        const obTotalDiscount = this.totals.discounts[index];
        if (obTotalDiscount) {
          fDisplayValue = obTotalDiscount.amount;
        }
      }

      if (
        obDiscount.get("limit") !== fPrice ||
        obDiscount.get("display_value") !== fDisplayValue ||
        obDiscount.get("disabled") !== bDisabled
      ) {
        obDiscount.set("disabled", bDisabled);
        obDiscount.set("limit", fPrice);
        obDiscount.set("display_value", fDisplayValue);
        obDiscount.set("key", uniqueId());
        hasChanges = true;
      }

      fPrice = math.add(fPrice, fValue);

      if (!obDiscount.value) {
        bDisabled = true;
      }
    });

    return hasChanges;
  }

  /**
   * Calculate amount of all discounts
   */
  setDiscountAmount() {
    // this.cleanItems();
    // this.addToCollection();
    // let fSubtotal = this.fSubtotalWithoutDiscounts;
    // let fLimit = this.priceWithTax
    //   ? this.getTotalWithoutDiscounts()
    //   : this.fSubtotalWithoutDiscounts;
    // const arDiscounts: DiscountData[] = [];
    //
    // //@ts-ignore
    // this.obDiscountCollection.each((obItem: Discount, index: number) => {
    //   obItem.set("priority", index + 1);
    //   obItem.set("limit", fLimit);
    //
    //   const fValue = obItem.calculate(fSubtotal);
    //   fSubtotal = math.add(fSubtotal, fValue);
    //
    //   obItem.set("amount", fValue);
    //
    //   arDiscounts.push(obItem.attributes as DiscountData);
    // });

    if (!this.obDiscountCollection.length && this.fSubtotal) {
      this.addToCollection();
    }

    if (!this.mapDiscounts()) {
      return;
    }

    const obCollection: DiscountCollection = this.obDiscountCollection.filter(
      (obItem: Discount) => !!obItem.value
    );
    this.obInvoice.set("discounts", obCollection.getModelList());
    this.forceReactive++;

    applyDiscounts(this.positions, this.discounts, this.priceWithTax);

    delay(() => {
      if (this.fTotalRounded) {
        this.addToCollection();
      }
    }, 500);
  }
}
</script>
